export type UseMutationReturn<TData, TArgs> = {
  mutate: (args: TArgs) => Promise<TData | undefined>
  loading: Ref<boolean>
  error: Ref<unknown>
  result: Ref<TData | undefined>
}

type OFetchOptions = Parameters<typeof $fetch>[1]

export function useMutation<TArgs extends unknown[], TData>(options: {
  successKey?: string
  onSuccess?: (result: TData) => Promise<unknown> | unknown
  beforeMutation?: () => unknown
  onError?: (error: unknown) => Promise<unknown>
  fetchFunction: (...args: TArgs) => Promise<TData>
}): {
  mutate(...args: TArgs): Promise<TData>
  loading: Ref<boolean>
  error: Ref<unknown>
  result: Ref<TData | undefined>
}

export function useMutation<TData>(options: {
  successKey?: string
  onSuccess?: (result: TData) => Promise<unknown> | unknown
  beforeMutation?: () => unknown
  onError?: (error: unknown) => Promise<unknown>
  url: string
  fetchOptions?: OFetchOptions
}): {
  mutate(): Promise<TData>
  loading: Ref<boolean>
  error: Ref<unknown>
  result: Ref<TData | undefined>
}

export function useMutation(
  options: {
    successKey?: string
    onSuccess?: (result: unknown) => Promise<unknown> | unknown
    beforeMutation?: () => unknown
    onError?: (error: unknown) => Promise<unknown>
  } & (
    | {
        url: string
        fetchOptions?: OFetchOptions
      }
    | {
        fetchFunction: (args: unknown) => Promise<unknown>
      }
  ),
) {
  const { $api } = useNuxtApp()
  const { t } = useI18n()
  const loading = ref(false)
  const error = ref<unknown>()
  const result = ref()
  const { displayError, displayNotification } = useNotifications()

  async function fetcher(args: unknown[]) {
    try {
      loading.value = true
      error.value = undefined

      if (options.beforeMutation) {
        options.beforeMutation()
      }

      if ("fetchFunction" in options) {
        result.value = await Promise.resolve(options.fetchFunction(args))
      } else {
        const defaultFetchOptions: OFetchOptions = { method: "post" }
        // Nuxt/Nitro automatically infers response types from API route definitions,
        // which override the generic type provided to `$fetch`.
        // Since we're using a dummy API primarily for mocking, the inferred types are less relevant.
        // We explicitly cast the result to `TData` since our provided generic should be accurate.
        result.value = await $api(options.url, {
          ...defaultFetchOptions,
          ...options.fetchOptions,
        })
      }
      if (options.successKey) {
        displayNotification({
          description: t(options.successKey),
          type: "success",
        })
      }

      if (options.onSuccess) {
        await options.onSuccess(result.value)
      }

      loading.value = false
      return result.value
    } catch (e) {
      if (options.onError) {
        await options.onError(e)
      } else {
        displayError(e)
      }
      error.value = e
      loading.value = false
    }
  }
  return { mutate: fetcher, loading, error, result }
}
